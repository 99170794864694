import React, { useState } from "react";

const PUBLIC_KEY = "AIzaSyDkjuoBUQp6D8z0asW2_ExCMEHvLlde47o";
const login = async ({ email, password }) => {
  return fetch(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${PUBLIC_KEY}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        returnSecureToken: true,
      }),
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      //   console.log(err);
    });
};

const deleteFirebaseAccount = async (idToken) => {
  return fetch(`https://identitytoolkit.googleapis.com/v1/accounts:delete?key=${PUBLIC_KEY}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idToken,
    }),
  })
    .then((response) => response.json())
    .catch((err) => {
      //   console.log(err);
    });
};

const buttonStyle = {
  backgroundColor: "#136ad5",
  borderRadius: "5px",
  borderStyle: "none",
  color: "#FFFFFF",
  cursor: "pointer",
  display: "inline-block",
  fontSize: "16px",
  fontWeight: "500",
  outline: "none",
  padding: "10px 22px",
  textAlign: "center",
  textDecoration: "none",
  userSelect: "none",
};

function DeleteAccount() {
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [authInfo, setAuthInfo] = useState(null);
  const [isDeleted, setDeleted] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await login({ email: inputEmail, password: inputPassword });
    if (response?.idToken) {
      setAuthInfo(response);
    } else if (response?.error?.message) {
      alert(response?.error?.message);
    }
  };

  const onDelete = async () => {
    try {
      await deleteFirebaseAccount(authInfo.idToken);
      setDeleted(true);
    } catch (error) {}
  };
  return (
    <div
      style={{
        margin: 50,
      }}
    >
      <h1>Delete Account</h1>
      {authInfo ? (
        <div>
          {isDeleted ? (
            <div>
              <p>The Tobo account for {authInfo.email} is deleted.</p>
            </div>
          ) : (
            <div>
              <p>Do you want to delete the Tobo account for {authInfo.email}</p>
              <button onClick={onDelete} style={buttonStyle}>
                Delete
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p>Login to the Tobo account you want to delete.</p>
          <form onSubmit={handleSubmit}>
            <div>
              <label style={{ width: 80, display: "inline-block" }}>Email:</label>
              <input
                type="email"
                value={inputEmail}
                required
                style={{ margin: "5px 0", padding: "5px 10px", fontSize: 14, width: 180 }}
                onChange={(e) => {
                  setInputEmail(e.target.value);
                }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <label style={{ width: 80, display: "inline-block" }}>Password:</label>
              <input
                type="password"
                value={inputPassword}
                required
                style={{ margin: "5px 0", padding: "5px 10px", fontSize: 14, width: 180 }}
                onChange={(e) => {
                  setInputPassword(e.target.value);
                }}
              />
            </div>
            <input type="submit" value="Login" style={buttonStyle} />
          </form>
        </div>
      )}
    </div>
  );
}

export default DeleteAccount;
